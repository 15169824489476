import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header>
        <h1 className="name">tadhg o'brien</h1>
      </header>
      <main>
        <Link to="/projects" className="projects-link">projects</Link>
      </main>
      <footer>
      </footer>
    </div>
  );
};

export default LandingPage;